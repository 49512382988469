import React from 'react'
import styled from 'styled-components'
import Paragraph from './elements/paragraph'

const Label = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fontSizes[9]};
  line-height: ${({ theme }) => theme.verticalRythm[9]};
  text-transform: uppercase;
  margin: 0;
`

export default ({ children, htmlFor, className }) => (
  <Label
    as="label"
    htmlFor={ htmlFor }
    className={className}
  >
    {children}
  </Label>
)