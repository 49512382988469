import React from 'react'
import styled from 'styled-components'
import v4 from 'uuid'

const Select = styled.select`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes[9]};
  line-height: ${({ theme }) => theme.verticalRythm[9]};
  padding: ${({ theme }) => theme.verticalRythm[7]};
  -webkit-appearance: none;
  appearance: none;
  border-radius: ${({ theme }) => theme.radii[1]};
  height: ${({ theme }) => theme.verticalRythm[10]};
  background-color: #fff;

  &::placeholder {
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.gray[800]};
  }
`

export default ({ selectOptions, value, handleChange, className }) => (
  <Select
    id="subject"
    value={value}
    name="subject"
    placeholder="subject"
    onChange={handleChange}
    required
    aria-required="true"
    className={className}
    defaultValue=""
  >
    <option
      value=""
      disabled
      hidden
      style={{display: 'none'}}
    >
      Select subject
    </option>

    {selectOptions.map(option => (
      <option
        key={v4()}
        value={option}
      >
        {option}
      </option>
    ))}
  </Select>
)
