import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes[9]};
  line-height: ${({ theme }) => theme.verticalRythm[9]};
  padding: ${({ theme }) => theme.verticalRythm[7]};
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: none;
  border: none;
  outline: 0;
  -webkit-appearance: none;
  appearance: none;
  height: ${({ theme }) => theme.verticalRythm[10]};
  background-color: #fff;

  &::placeholder {
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.gray[800]};
  }
`

export default ({ id, type, name, placeholder, onChange, className }) => (
  <Input
    as="input"
    id={id}
    type={type}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    required
    className={className}
  />
)

