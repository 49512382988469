import React, { useState } from 'react'
import { navigate } from 'gatsby-link'
import encode from './form-encode'
import Label from './form-label'
import Input from './form-input'
import Button from './form-button'
import Select from './form-select'
import Textarea from './form-textarea'

export default ({ form: { email, name, select, selectOptions, textarea, submit } }) => {
  const [state, setState] = useState({})

  const handleChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.target

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
    .then(() => navigate(form.getAttribute('action')))
    .catch(error => console.error(error))
  }

  return (
    <form
      name="contact"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input
        type="hidden"
        name="form-name"
        value="contact"
      />
      <div hidden>
        <label>
          Don’t fill this out: <input name="bot-field" onChange={handleChange} />
        </label>
      </div>

      <div>
        <Label htmlFor="name">
          {name}:
        </Label>
        <Input
          id="name"
          type="text"
          name="name"
          placeholder={`Your name`}
          required="true"
          onChange={handleChange}
        />
      </div>
      <div>
        <Label
          htmlFor="email">
            {email}:
          </Label>
        <Input
          id="email"
          type="email"
          name="email"
          placeholder={`Your email`}
          onChange={handleChange}
          required="true"
        />
      </div>
      <div>
        <Label htmlFor="subject">
          {select}:
        </Label>
        <Select
          id="subject"
          selectOptions={selectOptions}
          value={state.subject}
          name="subject"
          placeholder={`Subject`}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="message">
          {textarea}:
        </Label>
        <Textarea
          id="message"
          name="message"
          placeholder={`Extra details`}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Button type="submit">
          {submit}
        </Button>
      </div>
    </form>
  )
}
