import React from 'react'
import styled from 'styled-components'
import ButtonFullLg from './styles/button-full-lg'

const Submit = styled.button`
  ${ButtonFullLg}
`

export default ({ type, children, className }) => (
  <Submit
    type={type}
    className={className}
  >
    {children}
  </Submit>
)
