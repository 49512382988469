import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Ast from '../components/ast'
import Seo from '../components/seo'
import Form from '../components/form'
import Heading from '../components/page-heading'
import SocialMedia from '../components/social-media'
import useSiteMetadata from '../hooks/use-site-metadata'

export default ({ data: { allContentfulContactPage: { edges } } }) => {
  const { node } = edges[0]
  const { title, body, form } = node
  const htmlAst = body.childMarkdownRemark.htmlAst
  const meta = useSiteMetadata()
  const {
    site: {
      siteMetadata: {
        facebookUrl,
        linkedinUrl
      }
    }
  } = meta

  return (
    <Layout customSeo={`true`}>
      <Seo contactPage={node} />
      <Heading>{title}</Heading>
      <Ast ast={htmlAst} />
      <SocialMedia
        facebook={facebookUrl}
        linkedin={linkedinUrl}
      />
      <Form form={form} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactPage {
    allContentfulContactPage {
      edges {
        node {
          title
          path {
            url
            label
          }
          body {
            childMarkdownRemark {
              html
              htmlAst
            }
          }
          form {
            name
            email
            select
            selectOptions
            textarea
            requiredText
            submit
          }
        }
      }
    }
  }
`
