import React from 'react'
import styled from 'styled-components'
import { Facebook } from 'styled-icons/fa-brands/Facebook'
import { Twitter } from 'styled-icons/fa-brands/Twitter'
import { Linkedin } from 'styled-icons/fa-brands/Linkedin'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledFb = styled(Facebook)`
  margin: ${({ theme }) => theme.verticalRythm[9]};
  display: flex;
  width: ${({ theme }) => theme.verticalRythm[9]};
  height: ${({ theme }) => theme.verticalRythm[9]};
  color: ${({ theme }) => theme.colors.primary.default};
`

const StyledTwitter = styled(Twitter)`
  margin: ${({ theme }) => theme.verticalRythm[9]};
  display: flex;
  width: ${({ theme }) => theme.verticalRythm[9]};
  height: ${({ theme }) => theme.verticalRythm[9]};
  color: ${({ theme }) => theme.colors.primary.default};
`

const StyledLinkedin = styled(Linkedin)`
  margin: ${({ theme }) => theme.verticalRythm[9]};
  display: flex;
  width: ${({ theme }) => theme.verticalRythm[9]};
  height: ${({ theme }) => theme.verticalRythm[9]};
  color: ${({ theme }) => theme.colors.primary.default};
`

export default ({ facebook, linkedin }) => (
  <Container>
    <a href={facebook}><StyledFb /></a>
    <a href={linkedin}><StyledLinkedin /></a>
  </Container>
)